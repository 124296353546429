import './static/css/f7icons.css'
import './static/css/fonts.css'

window.apiUrl = 'https://connect.neilyoungarchives.com/api'
//window.apiUrl = 'http://localhost/api'
window.connectUrl = 'https://connect.neilyoungarchives.com'
//window.connectUrl = 'http://localhost'
window.cdnUrl = 'https://cdn.neilyoungarchives.com'

const loadJs = src => {
    return new Promise((resolve) => {    
        let script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = src
        script.onload = resolve
        document.body.appendChild(script)
    })
}

(async () => {
    let all = []
    all.push(loadJs('js/os.js'))    
    await Promise.all(all)

    await new Promise(r => setTimeout(r, 1500)) 
    await import('./main')
})()